<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ resellerService.name }} - {{ $t("COMMON.GALLERY") }}
      </span>
    </div>

    <div class="row" style="">
      <div
        class="col-4"
        v-for="(image, key) in resellerService.gallery"
        :key="key"
      >
        <img :src="image" class="argon-image mb-2" style="width: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reseller-service-view-gallery",

  components: {},

  props: ["resellerService"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    resellerService(resellerService) {},
  },
};
</script>
