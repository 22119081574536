export default {
  type: "reseller-services",
  name: null,
  excerpt: '',
  price: null,
  taxes: [],
  picture: null,
  gallery: [],
  relationshipNames: ["reseller"],
  reseller: {
    type: "resellers",
    id: null,
  },
};
